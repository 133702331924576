import React from 'react';
import AutosizeInput from 'react-input-autosize';

import type { ValueInputProps } from '../../types';
import type { ValueInputComponent } from '../../drivers';

import { style, classes } from './base-input.st.css';

export interface BaseInputState {
    editing: boolean;
}

// TODO: Save value on startEditing, restore it if we blur with esc
export default class BaseInput extends React.Component<ValueInputProps, BaseInputState> implements ValueInputComponent {
    public static type = 'BaseInput';

    public state: BaseInputState = { editing: false };

    private inputRef: HTMLInputElement | null = null;

    public input() {
        return this.inputRef;
    }
    public focus() {
        this.select();
    }
    public select() {
        !this.state.editing && this.startEditing();
    }
    public blur() {
        this.state.editing && this.stopEditing();
    }
    public suggestionsShown() {
        return false;
    }
    public suggestionsUsed() {
        return false;
    }

    public render() {
        const { value, onKeyDown, className } = this.props;

        const dataKeyClass = `baseInput_${this.props['data-key']}`;

        return !this.state.editing ? (
            <span
                className={style(classes.root, classes.displayRoot, dataKeyClass, className)}
                tabIndex={0}
                onClick={this.startEditing}
                onFocus={this.startEditing}
                title={this.getTitle()}
            >
                {value}
            </span>
        ) : (
            <AutosizeInput
                className={style(classes.root, dataKeyClass, className)}
                inputClassName={classes.nativeInput}
                value={value}
                type="text"
                spellCheck={false}
                onChange={this.handleChange}
                onClick={(event) => event.stopPropagation()}
                onDoubleClick={(event) => event.stopPropagation()}
                onKeyDown={onKeyDown}
                onBlur={this.stopEditing}
                inputRef={(ref) => (this.inputRef = ref)}
            />
        );
    }

    public componentDidUpdate(_prevProps: ValueInputProps, { editing: prevEditing }: BaseInputState) {
        const { editing } = this.state;
        const { onFocus, onBlur } = this.props;

        if (!prevEditing && editing) {
            this.inputRef && this.inputRef.select();
            onFocus && onFocus();
        }
        if (prevEditing && !editing) {
            onBlur && onBlur();
        }
    }

    private startEditing = (event?: React.MouseEvent<HTMLSpanElement> | React.FocusEvent<HTMLSpanElement>) => {
        this.setState({ editing: true });
        event && event.stopPropagation();
    };

    private stopEditing = () => this.setState({ editing: false });

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange && this.props.onChange(event.target.value);
    };

    private getTitle() {
        const { value, sheetDriver } = this.props;

        if (sheetDriver) {
            const extractedValue = sheetDriver.evalDeclarationValue(value);
            return extractedValue !== value ? extractedValue : undefined;
        }

        return undefined;
    }
}
