import chroma from 'chroma-js';

export const colDef = (color: string, start: number, end: number) => `${color} ${start}%, ${color} ${end}%`;

export const gradDef = (method: 'radial-gradient' | 'linear-gradient', options: string, defs: string[]) =>
    `${method}(${options}, ${defs.join(', ')})`;

export const diagonalLinesGenerator = (
    backgroundColor: string,
    layers: Array<{
        patternDirection: number;
        primaryWidth: number;
        secondaryWidth: number;
        primaryColor: string;
    }>
) => {
    const layersString = layers
        .map((layer) => {
            const bgImage = `repeating-linear-gradient(
                ${layer.patternDirection * 45}deg,
                    transparent,
                  transparent ${layer.primaryWidth}px,
                    ${layer.primaryColor} ${layer.primaryWidth}px,
                    ${layer.primaryColor} ${layer.primaryWidth + layer.secondaryWidth}px
                  )`;
            const bgSize = '200%';

            return `0/${bgSize} ${bgImage}, `;
        })
        .join('');

    return {
        background: `${layersString} ${backgroundColor}`,
    };
};

export const textStroke = (spread: number, color: string) => {
    return `${spread}px ${spread}px ${color},-${spread}px ${spread}px ${color},-${spread}px -${spread}px ${color},${spread}px -${spread}px ${color}`;
};

export const textShadowFxGenerator = (count: number, color: string) => {
    const res = [`${color} 1px 1px`];
    for (let i = 0; i < count; i++) {
        res.push(`${color} -${i}px ${i}px`);
    }
    return res.join(',');
};

export const glazedTextShadowBottom = (count: number, color: string) => {
    const res = [];
    let sampleColor = chroma(color) as any;

    for (let i = 0; i < count; i++) {
        res.push(`${sampleColor} ${i}px ${i === 0 ? '' : '-'}${i}px 0`);
        sampleColor = chroma(sampleColor).alpha(0.5).darken(0.15).hex('rgb');
    }

    res.push(`rgba(0, 0, 0, 0.55) ${count}px -${count}px ${count - 1}px`);
    res.push(`rgba(0, 0, 0, 0.5) ${count}px -${count}px 1px`);

    return res.join(', ');
};

// export const themeShadows = (colStart: string, colEnd: string) => {
//   const rgbaStart =   hex2rgba(colStart, 1);
//   const rgbaEnd = hex2rgba(colEnd, 0.5);
//   console.log(rgbaStart, rgbaEnd)
//   return multiShadows(25, rgbaStart, rgbaEnd)
//   // return "";
// }
// export const multiShadows = (times: number, bgR: number, bgG: number, bgB: number, opacity: number): string => {
// export const multiShadows = (times: number, colStart: any, colEnd: any): string => {
//   let res = [];
//   let factor = 0;

//   const { r, g, b, alpha } = colStart;

//   for (let i=0; i < times; i++) {
//     factor = i; // todo: calc factor with colEnd

//     res.push(`${i}px ${i}px rgba(${r + factor}, ${g + factor}, ${b + factor}, ${alpha + factor})`);
//     // res.push(`${i}px ${i}px rgba(${bgR + factor}, ${bgG + factor}, ${bgB + factor}, ${opacity}), -${i}px -${i}px rgba(${i}, ${i * 3}, ${i * 8}, ${opacity})`)
//     // res.push(`${Math.sin(i) * Math.cos(i)}px ${Math.sin(i) / Math.cos(i)}px rgba(${bgR + factor}, ${bgG + factor}, ${bgB + factor}, ${((i % 2) / 100) * 2})`)
//     // res.push(`${i % 2 === 0 ? i : (i*0.5)*-1}px ${i}px rgba(${bgR + factor}, ${i % 2 ? bgG+bgR : bgG + factor}, ${bgB + factor}, ${opacity})`)
//   }

//   return res.join(',');
// }
