export interface NumberWithUnit {
    value?: number;
    unit?: string;
}

export interface UnitEditInfo {
    unit: string;
    min: number;
    max: number;
    step: number;
    optional?: boolean;
    maxRange?: number;
    shiftStep?: number;
}

export function parseNumberUnit(value: string): NumberWithUnit {
    if (!value) {
        return {};
    }

    const noZeroesValue = value.replace(/^0+/, '');
    if (`0${noZeroesValue}` !== value) {
        value = noZeroesValue;
    }

    if (value[0] === '.') {
        value = `0${value}`;
    }

    const numValue = parseFloat(value);
    if (isNaN(numValue)) {
        return {};
    }

    let unit = '';
    const unitMatch = value.match(/\d*(\D*)$/);
    if (unitMatch) {
        unit = unitMatch[1] || '';
    }

    return { value: numValue, unit };
}
