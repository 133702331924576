import React from 'react';

import { style, classes } from './background-box.st.css';

const DEFAULT_FALLBACK_VALUE = 'transparent';

export interface BackgroundBoxProps {
    value?: string;
    selected?: boolean;
    noColor?: boolean;
    showNoColorDiagonal?: boolean;
    fallbackValue?: string;
    onClick?: () => void;
    className?: string;
    'data-aid'?: string;
}

export const BackgroundBox: React.FunctionComponent<BackgroundBoxProps> = ({
    value,
    selected = false,
    noColor = false,
    showNoColorDiagonal = false,
    fallbackValue = DEFAULT_FALLBACK_VALUE,
    onClick,
    className,
    ['data-aid']: dataAid,
}) => (
    <span
        className={style(
            classes.root,
            {
                selected,
                noColor,
                showNoColorDiagonal,
            },
            className
        )}
    >
        <span
            className={classes.backgroundBox}
            style={{ background: !noColor ? value : fallbackValue }}
            onClick={onClick}
            data-aid={dataAid}
        >
            {noColor && showNoColorDiagonal ? <div className={classes.noColorDiagonal}> </div> : ' '}
        </span>
    </span>
);
