import { IconRadioGroupInputFactory } from './icon-radio-group-input-factory';
import ChildDisplayDropdown, { AllElementsDisplayed } from './child-display-dropdown';
import FlexChildSpacing from './flex-child-spacing';
import {
    DirectionRowIcon,
    DirectionRowIconVertical,
    DirectionRowReverseIcon,
    DirectionRowReverseIconVertical,
    DirectionColumnIcon,
    DirectionColumnIconVertical,
    DirectionColumnReverseIcon,
    DirectionColumnReverseIconVertical,
    JustifyLeftIcon,
    JustifyRightIcon,
    JustifyCenterIcon,
    JustifySpaceIcon,
    JustifyLeftSelectedIcon,
    JustifyCenterSelectedIcon,
    JustifyRightSelectedIcon,
    JustifySpaceSelectedIcon,
} from './layout-icons';

const FlexDirectionInput = IconRadioGroupInputFactory(
    [
        { propValue: 'row', icon: { default: DirectionRowIcon, defaultVertical: DirectionRowIconVertical } },
        {
            propValue: 'row-reverse',
            icon: { default: DirectionRowReverseIcon, defaultVertical: DirectionRowReverseIconVertical },
        },
        { propValue: 'column', icon: { default: DirectionColumnIcon, defaultVertical: DirectionColumnIconVertical } },
        {
            propValue: 'column-reverse',
            icon: { default: DirectionColumnReverseIcon, defaultVertical: DirectionColumnReverseIconVertical },
        },
    ],
    'row',
    true
);

// TODO: Should set align-items to center when it renders
const JustifyContentInput = IconRadioGroupInputFactory(
    [
        {
            propValue: 'flex-start',
            icon: {
                default: JustifyLeftIcon,
                checked: JustifyLeftSelectedIcon,
            },
        },
        {
            propValue: 'center',
            icon: {
                default: JustifyCenterIcon,
                checked: JustifyCenterSelectedIcon,
            },
        },
        {
            propValue: 'flex-end',
            icon: {
                default: JustifyRightIcon,
                checked: JustifyRightSelectedIcon,
            },
        },
        {
            propValue: 'space-between',
            icon: {
                default: JustifySpaceIcon,
                checked: JustifySpaceSelectedIcon,
            },
        },
    ],
    'flex-start'
);

const SingleElementJustifyContentInput = IconRadioGroupInputFactory(
    [
        {
            propValue: 'flex-start',
            icon: {
                default: JustifyLeftIcon,
                checked: JustifyLeftSelectedIcon,
            },
        },
        {
            propValue: 'center',
            icon: {
                default: JustifyCenterIcon,
                checked: JustifyCenterSelectedIcon,
            },
        },
        {
            propValue: 'flex-end',
            icon: {
                default: JustifyRightIcon,
                checked: JustifyRightSelectedIcon,
            },
        },
    ],
    'flex-start'
);

// TODO: Should set justify-content to center when it renders
const AlignItemsInput = IconRadioGroupInputFactory(
    [
        {
            propValue: 'flex-start',
            icon: {
                default: JustifyLeftIcon,
                checked: JustifyLeftSelectedIcon,
            },
        },
        {
            propValue: 'center',
            icon: {
                default: JustifyCenterIcon,
                checked: JustifyCenterSelectedIcon,
            },
        },
        {
            propValue: 'flex-end',
            icon: {
                default: JustifyRightIcon,
                checked: JustifyRightSelectedIcon,
            },
        },
    ],
    'flex-start'
);

export {
    FlexDirectionInput,
    JustifyContentInput,
    SingleElementJustifyContentInput,
    AlignItemsInput,
    ChildDisplayDropdown,
    FlexChildSpacing,
    AllElementsDisplayed,
};
