export const extractURL = (allowNoQuotes: boolean) => (value: string) => {
    const withQuotesRegex = new RegExp(/url\(['"](.*)['"]\)/);
    let regexMatch = value.match(withQuotesRegex);

    if (!regexMatch && allowNoQuotes) {
        const withoutQuotesRegex = new RegExp(/url\((.*)\)/);
        regexMatch = value.match(withoutQuotesRegex);
    }
    return regexMatch ? regexMatch[1] : value;
};

export function wrapURL(value: string) {
    return `url('${value}')`;
}
