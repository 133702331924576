import chroma from 'chroma-js';

const WHITE_DISTANCE_THRESHOLD = 3;
const MAX_DISTANCE_TO_WHITE = 100;

export interface HSVColor {
    hue: number;
    saturation: number;
    value: number;
}

function getDistanceToWhite(color?: chroma.Color) {
    if (!color) {
        return MAX_DISTANCE_TO_WHITE;
    }

    const [saturation, brightness] = color.hsv().slice(1);

    return Math.sqrt(Math.pow(100 - brightness * 100, 2) + Math.pow(saturation * 100, 2));
}

export function isWhite(color: chroma.Color) {
    return getDistanceToWhite(color) < WHITE_DISTANCE_THRESHOLD;
}

export function colorFromHSVColor(hsvColor: HSVColor): chroma.Color {
    return chroma.hsv(hsvColor.hue, hsvColor.saturation / 100, hsvColor.value / 100);
}

export function hsvColorFromColor(color: chroma.Color): HSVColor {
    const hue = color.get('hsv.h') || 0;
    return {
        hue,
        saturation: color.get('hsv.s') * 100,
        value: color.get('hsv.v') * 100,
    };
}
