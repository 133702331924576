import { KeywordVisualizerFactory } from '../visualizer-factories/keyword-visualizer-factory/keyword-visualizer-factory';

export const FontWeightVisualizer = KeywordVisualizerFactory(
    'font-weight',
    {
        options: [
            {
                id: '100',
                displayName: '100',
            },
            {
                id: '200',
                displayName: '200',
            },
            {
                id: '300',
                displayName: '300',
            },
            {
                id: '400',
                displayName: '400',
            },
            {
                id: '500',
                displayName: '500',
            },
            {
                id: '600',
                displayName: '600',
            },
            {
                id: '700',
                displayName: '700',
            },
            {
                id: '800',
                displayName: '800',
            },
            {
                id: '900',
                displayName: '900',
            },
        ],
    },
    '400'
);
