import React from 'react';

import { extractURL } from '../../utils/url-utils';

import { style, classes } from './image-picker.st.css';

export interface ImagePickerProps {
    title?: string;
    value?: string;
    onChange?: (value: string) => void;
    className?: string;
}

export interface ImagePickerState {
    value: string;
}

export class ImagePicker extends React.Component<ImagePickerProps, ImagePickerState> {
    public static panelName = 'image';
    public static getDerivedStateFromProps(props: ImagePickerProps, _state: ImagePickerState) {
        if (props.value) {
            return { value: extractURL(true)(props.value) };
        }

        return { value: undefined };
    }

    public state: ImagePickerState = { value: '' };

    public render() {
        const { className } = this.props;

        return (
            <input
                className={style(classes.root, className)}
                type="text"
                value={this.state.value}
                onChange={this.handleInputChange}
            />
        );
    }

    private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        this.props.onChange && this.props.onChange(`urlDummy("${value}")`);
        this.setState({ value });
    };
}
