export const palettes = [
    [
        ['#2D2D2D', '#575757', '#818181', '#ABABAB', '#E8E8E8'],
        ['#542000', '#A84000', '#FC6000', '#FDAF7E', '#FEC9A9'],
        ['#000000', '#333333', '#666666', '#999999', '#FFFFFF'],
        ['#1F3141', '#3E6282', '#5E93C4', '#9FBCD7', '#C2D7EB'],
        ['#4E3206', '#9D640C', '#EC9712', '#F2C682', '#F8DAAC'],
    ],
    [
        ['#EDA5A6', '#DD7779', '#CC1013', '#880A0C', '#440506'],
        ['#EDEDED', '#ABABAB', '#828282', '#565656', '#000000'],
        ['#FFF7AA', '#FFF47F', '#FFE900', '#A99B00', '#544D00'],
        ['#FFFFFF', '#ABABAB', '#828282', '#565656', '#2B2B2B'],
        ['#A8E0F4', '#7CCDEA', '#0DA8E0', '#087095', '#04384A'],
    ],
    [
        ['#F9E2A6', '#F4D27A', '#F7B800', '#9F7200', '#4F3900'],
        ['#FEFDFD', '#D5D4D4', '#000000', '#000000', '#000000'],
        ['#EBBCB1', '#D79788', '#C45033', '#823522', '#411A11'],
        ['#ECD3B2', '#D9B689', '#C68733', '#845A22', '#422D11'],
        ['#D6BBC8', '#AD909E', '#846374', '#5B2D44', '#2D1622'],
    ],
    [
        ['#A1DCF2', '#72C6E5', '#009ED8', '#006990', '#003448'],
        ['#FFFFFF', '#E3E3E3', '#747575', '#464747', '#181919'],
        ['#CCE4A0', '#AACA70', '#79AF13', '#50750C', '#283A06'],
        ['#D4D4D4', '#A9A9A9', '#7F7F7F', '#545454', '#2A2A2A'],
        ['#FCDCAD', '#F9CA84', '#F79A0E', '#A46609', '#523304'],
    ],
    [
        ['#3D2C2A', '#7B5955', '#B98580', '#F7B2AA', '#FBD8D4'],
        ['#323232', '#656565', '#989897', '#CBCACA', '#FEFDFD'],
        ['#895C3C', '#B0937F', '#D7C0AF', '#EDD8C7', '#F7ECE4'],
        ['#472C0C', '#8E5918', '#D68624', '#E3B985', '#F1D3AE'],
        ['#3C341E', '#79693C', '#B59D5B', '#F2D279', '#F8E8BA'],
    ],
    [
        ['#D0E5DE', '#B3D9CC', '#809A91', '#535B58', '#72858C'],
        ['#FFFFFF', '#BECDD1', '#92A6A2', '#72858C', '#5C686C'],
        ['#DACCB3', '#B59F74', '#887757', '#5A4F3A', '#2D271D'],
        ['#DDEBC3', '#C4D7A0', '#A1C460', '#6B8240', '#354120'],
        ['#FFBED5', '#FF9EC0', '#FF3D81', '#A92856', '#54142B'],
    ],
    [
        ['#FFFFFF', '#F2F2F2', '#CFCFCF', '#A8A8A8', '#606060'],
        ['#A4E6F7', '#77D7EF', '#00B9E8', '#007B9A', '#003D4D'],
        ['#F0ADA0', '#E28371', '#D32300', '#8D1700', '#460B00'],
        ['#D3E9AA', '#B6D47E', '#89BF24', '#5B7F18', '#2D3F0C'],
        ['#FFEFAA', '#FFE77F', '#FFD000', '#A98A00', '#544500'],
    ],
    [
        ['#F3E0B3', '#EAD38D', '#DDA92E', '#93701F', '#493508'],
        ['#EDA1A1', '#DD7272', '#CC0808', '#880505', '#440202'],
        ['#FFFFFF', '#8D929A', '#5A5F67', '#424A56', '#282D35'],
        ['#F9C3AC', '#F4A582', '#EF5310', '#9F370B', '#4F1B05'],
        ['#DADAA6', '#B5B57B', '#90904B', '#6B6B05', '#353502'],
    ],
    [
        ['#DAEDFF', '#AFCBE0', '#6EA4CA', '#39729B', '#0C3C60'],
        ['#FFFFFF', '#95C6EC', '#1B99E8', '#1468A8', '#062134'],
        ['#B3C0D3', '#8794A7', '#5C697B', '#313E4F', '#081423'],
        ['#B2D2CB', '#86A69E', '#5A7972', '#2F4D46', '#07211B'],
        ['#B8B4D3', '#8C88A7', '#615D7B', '#36324F', '#0D0923'],
    ],
    [
        ['#F9D5A6', '#F3BE79', '#ED8600', '#9E5900', '#4F2C00'],
        ['#FFFFFF', '#94A2AE', '#6B7986', '#42505D', '#152635'],
        ['#CFCFCF', '#8396A5', '#576978', '#2C3D4B', '#05131E'],
        ['#FFFFFF', '#F0F0F0', '#666666', '#333333', '#000000'],
        ['#F7C4A9', '#EFA67F', '#E85A0D', '#9A3C09', '#4D1E04'],
    ],
    [
        ['#474646', '#767676', '#A6A4A4', '#DEDDDD', '#FFFFFF'],
        ['#00544D', '#1A887E', '#00A99D', '#8DD3CD', '#AEE9E4'],
        ['#41431D', '#82863B', '#C3C958', '#D8DB9D', '#EAEDC0'],
        ['#3B382B', '#767057', '#B1A883', '#EDE0AF', '#F6EFD6'],
        ['#291C24', '#533948', '#7C566D', '#A88E9D', '#D3BDCA'],
    ],
    [
        ['#E0E0E0', '#C8C8C8', '#8E8E8B', '#5F5E5D', '#454545'],
        ['#E5EBFF', '#A1C3D2', '#515D87', '#132460', '#091230'],
        ['#DCEBF1', '#A1C3D2', '#98A9B0', '#637E89', '#42545B'],
        ['#E3AEAE', '#C88989', '#AC5B5B', '#910808', '#480404'],
        ['#FFFFFF', '#B0B0B0', '#898989', '#585858', '#2A2A2A'],
    ],
    [
        ['#1A1A1A', '#565656', '#999797', '#D4D3D3', '#FFFFFF'],
        ['#533913', '#A67327', '#F9AD3B', '#FBD59B', '#FDE3BD'],
        ['#49150D', '#932A1A', '#DD4027', '#E89689', '#F3BAB1'],
        ['#3A0C1B', '#751936', '#AF2652', '#CA7B94', '#E4A9BC'],
        ['#360F30', '#6D1E61', '#915D8A', '#B68AAF', '#DAB3D4'],
    ],
    [
        ['#FFFFFF', '#D9D9D9', '#909090', '#555555', '#000000'],
        ['#F9BAB0', '#F39789', '#ED3A1E', '#9E2614', '#4F130A'],
        ['#FFF6AA', '#FFF27F', '#FFE500', '#A99800', '#544C00'],
        ['#9CDDEB', '#6BC4D7', '#00A1C3', '#006B82', '#003541'],
        ['#B1E8B5', '#8FD194', '#62BA69', '#08A316', '#04510B'],
    ],
    [
        ['#FFFFFF', '#B49A8D', '#8F7365', '#6A4B3B', '#441C08'],
        ['#FFEEAA', '#FFE57F', '#FFCC00', '#A98700', '#544300'],
        ['#C9D1AD', '#9BA47E', '#6D774D', '#3D4916', '#1E240B'],
        ['#ADD2E9', '#82B5D4', '#2B86BF', '#1D597F', '#0E2C3F'],
        ['#DCC9B8', '#BBA28D', '#99714E', '#664B34', '#33251A'],
    ],
    [
        ['#FFFFFF', '#999999', '#666666', '#333333', '#000000'],
        ['#D9D9D9', '#D6D6D6', '#AFAFAF', '#828282', '#000000'],
        ['#FBABE7', '#F882DA', '#F40CBA', '#A3087C', '#51043E'],
        ['#D7D1C7', '#AFA99E', '#888074', '#605644', '#302B22'],
        ['#D9CEC6', '#B4A69C', '#8E7969', '#5F5146', '#2F2823'],
    ],
    [
        ['#FFFFFF', '#BFBFBF', '#808080', '#404040', '#000000'],
        ['#F3C5A9', '#E8A67D', '#E75101', '#933F0B', '#491F05'],
        ['#BDD4D6', '#8FABAD', '#578284', '#3A5658', '#1D2B2C'],
        ['#DBD9D3', '#B7B5AE', '#939085', '#626058', '#31302C'],
        ['#D6D2CA', '#ADA9A0', '#847F76', '#5B5547', '#2D2A23'],
    ],
    [
        ['#FFFFFF', '#999999', '#666666', '#333333', '#000000'],
        ['#FDAAEA', '#FB7FE0', '#F904C3', '#A60282', '#530141'],
        ['#BCC2D3', '#9096A7', '#656A7B', '#3A3F4F', '#101523'],
        ['#D6C0D4', '#AD97AB', '#846E82', '#5B4459', '#331830'],
        ['#D4B0C4', '#AA8499', '#80546D', '#561B3C', '#2B0D1E'],
    ],
    [
        ['#FFD5AA', '#FFC17F', '#FF8300', '#A95700', '#542B00'],
        ['#FEFDFD', '#E3E3E3', '#919090', '#5C5C5C', '#000000'],
        ['#A1C1D3', '#7394A8', '#42677C', '#043551', '#021A28'],
        ['#E79C9C', '#CF6A6A', '#B70505', '#7A0303', '#3D0101'],
        ['#9EBAE2', '#6C92C6', '#0F50AA', '#0A3571', '#051A38'],
    ],
    [
        ['#000000', '#333333', '#7F7E7E', '#DDDDDD', '#FFFFFF'],
        ['#514D03', '#A39B06', '#F4E909', '#F8F281', '#FBF7AB'],
        ['#00494C', '#009399', '#00DDE5', '#76EAED', '#A4F3F6'],
        ['#51002B', '#A30057', '#F40082', '#F87CBE', '#FBA7D4'],
        ['#004E14', '#009D29', '#00EC3D', '#79F298', '#A5F8BB'],
    ],
    [
        ['#FFFFFF', '#DAE39B', '#95A712', '#535A0A', '#252A04'],
        ['#D4D0D3', '#A9A5A8', '#7E7A7D', '#534F52', '#282527'],
        ['#F3EEE1', '#E8DFC5', '#999170', '#514920', '#282410'],
        ['#F2C3B1', '#E5A389', '#D85C2B', '#903D1C', '#481E0E'],
        ['#EDB6BC', '#DD8F97', '#CC3D4B', '#882832', '#441419'],
    ],
    [
        ['#FFFFFF', '#E9E4D1', '#D6CDAB', '#97917D', '#696554'],
        ['#E9E4D1', '#CDC5BF', '#B49074', '#996944', '#403027'],
        ['#C9D3C5', '#98A892', '#727E6D', '#4C5449', '#262A24'],
        ['#ACCEE8', '#66A2D1', '#4C7A9C', '#335168', '#192834'],
        ['#F1E3C3', '#E3D0A2', '#D6B15C', '#8E763D', '#473B1E'],
    ],
    [
        ['#DBC8B5', '#B8A28E', '#957B62', '#724923', '#392411'],
        ['#FCF4C9', '#F9EA95', '#BBB070', '#7C754A', '#3E3A25'],
        ['#FFFFFF', '#CBCACA', '#989797', '#656565', '#323232'],
        ['#E7C8AD', '#CFAA8A', '#B8875C', '#A04A00', '#502500'],
        ['#CDD8B4', '#A4B285', '#768C46', '#4E5D2E', '#272E17'],
    ],
    [
        ['#F3F3F3', '#D6D6D6', '#AAA8A8', '#717070', '#4A4A4A'],
        ['#AADDFF', '#7FCCFF', '#0099FF', '#0065A9', '#003254'],
        ['#FFEEAA', '#FFE57F', '#FFCC00', '#A98700', '#544300'],
        ['#C4E7B6', '#A0CF8E', '#64B743', '#437A2D', '#213D16'],
        ['#E0A1E0', '#C170C1', '#A31AA3', '#6C116C', '#360836'],
    ],
    [
        ['#1B1B1B', '#545454', '#7F7F7F', '#A9A9A9', '#FFFFFF'],
        ['#051324', '#0B2749', '#445B77', '#758AA4', '#A5B9D1'],
        ['#390E04', '#731D08', '#AD2C0C', '#C87D6B', '#E3AB9D'],
        ['#11213A', '#224375', '#3364AF', '#829FCA', '#AEC4E4'],
        ['#3D3D1E', '#7A7A3D', '#B7B75B', '#CFCF9B', '#E7E7C0'],
    ],
    [
        ['#D4CAC5', '#A99E98', '#7F6E64', '#544943', '#2A2421'],
        ['#FFFFFF', '#A9A9A9', '#7F7F7F', '#545454', '#2A2A2A'],
        ['#CCCCCC', '#999999', '#666666', '#333333', '#000000'],
        ['#E2BEAE', '#C69C89', '#AA745B', '#8E3409', '#471A04'],
        ['#C7D5E1', '#A2B3C3', '#6D8BA5', '#485C6E', '#242E37'],
    ],
    [
        ['#000000', '#545454', '#7F7F7F', '#DBD8D8', '#FFFFFF'],
        ['#544F00', '#A99E00', '#FFEE00', '#FFF67F', '#FFF9AA'],
        ['#312F26', '#625E4D', '#948D73', '#C5BC9A', '#F7EBC0'],
        ['#1F2B2F', '#3F575E', '#5E838D', '#7EAFBC', '#B9D5DD'],
        ['#61772C', '#635E4C', '#958E73', '#C7BD99', '#F9EDC0'],
    ],
    [
        ['#FFFFFF', '#D8D1C6', '#A69376', '#826F52', '#645844'],
        ['#C9F8F8', '#71E8E8', '#37DDDD', '#2FC4C6', '#1A7373'],
        ['#E1E69D', '#C7CD6B', '#A9B709', '#717806', '#383C03'],
        ['#DAB3D4', '#B68AAF', '#915D8A', '#6D1E61', '#360F30'],
        ['#FCCBA8', '#F9B17C', '#F76700', '#A44400', '#522200'],
    ],
    [
        ['#CECDC9', '#9E9D99', '#6D6C68', '#3D3C37', '#1E1E1B'],
        ['#F7C4BB', '#EFA699', '#E85A40', '#9A3C2B', '#4D1E15'],
        ['#F7F2BB', '#EFE898', '#E8D93E', '#9A9129', '#4D4814'],
        ['#A1E4F0', '#72D1E2', '#02B4D3', '#01788D', '#003C46'],
        ['#B2DFD5', '#72BFAE', '#568F83', '#395F57', '#1C2F2B'],
    ],
    [
        ['#E0E0E0', '#B5B5B5', '#8D8D8D', '#585858', '#353535'],
        ['#FFFFFF', '#FFFF78', '#BFBF5A', '#6B6B2E', '#000000'],
        ['#ADD2E2', '#80B0C5', '#3284A8', '#215870', '#102C38'],
        ['#D0E3B1', '#AFC886', '#81AD3A', '#567327', '#2B3913'],
        ['#C8B8EE', '#A992DE', '#6C41CD', '#482B89', '#241544'],
    ],
    [
        ['#FFFFFF', '#B8BBC1', '#808286', '#404144', '#2D2D2F'],
        ['#FFDB89', '#FFC540', '#FFB400', '#B98000', '#5D4100'],
        ['#B0D3E5', '#8DB6CC', '#6096B3', '#0B689A', '#05344D'],
        ['#B9DEA9', '#8FBE7A', '#4F9E2D', '#35691E', '#1A340F'],
        ['#F5CBA4', '#ECAF77', '#E36D01', '#974901', '#4B2400'],
    ],
    [
        ['#FFFFFF', '#E3E3E3', '#ADADAD', '#757070', '#000000'],
        ['#D8F3FD', '#8CDCF8', '#00ADED', '#019CD2', '#00739E'],
        ['#EEC4F7', '#DE8CEF', '#A669B3', '#6F4677', '#37233B'],
        ['#FFCEAA', '#FFB67F', '#FF6E00', '#A94900', '#542400'],
        ['#FCF1A9', '#FAEA7E', '#F8D801', '#A59001', '#524800'],
    ],
    [
        ['#EFE4D5', '#BFB6AA', '#8F897F', '#5F5B55', '#2F2D2A'],
        ['#FFCBAE', '#FFB285', '#FF650C', '#A94308', '#542104'],
        ['#C4E2A7', '#9EC678', '#65AA23', '#437117', '#21380B'],
        ['#FFFFFF', '#999999', '#666666', '#333333', '#000000'],
        ['#F2E5A3', '#E5D276', '#D8B506', '#907904', '#483C02'],
    ],
    [
        ['#FFFFFF', '#A69EA7', '#7A727B', '#4E464F', '#221B23'],
        ['#D5E4AC', '#B6CA7F', '#8DAF2D', '#5E751E', '#2F3A0F'],
        ['#FFECAA', '#FFE380', '#FFC802', '#A98501', '#544200'],
        ['#F4BEA9', '#EA9D7E', '#E04C11', '#95320B', '#4A1905'],
        ['#E5DDC8', '#CCBE99', '#998E72', '#665F4C', '#332F26'],
    ],
    [
        ['#E3A2A6', '#C87278', '#AD1822', '#731016', '#39080B'],
        ['#FFFFFF', '#999999', '#666666', '#333333', '#000000'],
        ['#E3E0AF', '#C8C48A', '#ACA75D', '#91880B', '#484405'],
        ['#D1CBB6', '#A49E87', '#777058', '#494123', '#242011'],
        ['#C4DDD6', '#91BCAF', '#6C8D83', '#485E57', '#242F2B'],
    ],
    [
        ['#000000', '#192D39', '#1E3443', '#8394A0', '#FFFFFF'],
        ['#A99B00', '#FFE900', '#FBEC49', '#FFF056', '#FFF7AA'],
        ['#1B4410', '#378820', '#52CC30', '#9BDD89', '#BEEDB1'],
        ['#4B052E', '#970A5C', '#E20F8B', '#EC7EBE', '#F5A9D5'],
        ['#004254', '#0085A9', '#00C7FF', '#7FE3FF', '#AAECFF'],
    ],
    [
        ['#EFE4D5', '#BFB6AA', '#8F897F', '#5F5B55', '#0F0F0F'],
        ['#FFCBAE', '#FFB285', '#FF650C', '#A94308', '#542104'],
        ['#C4E2A7', '#9EC678', '#65AA23', '#437117', '#21380B'],
        ['#FFFFFF', '#999999', '#666666', '#333333', '#000000'],
        ['#F2E5A3', '#E5D276', '#D8B506', '#907904', '#483C02'],
    ],
    [
        ['#FFFFFF', '#999999', '#666666', '#444444', '#303030'],
        ['#AFE0EA', '#8ECAD6', '#60B1C1', '#0090AD', '#004856'],
        ['#DEA6B2', '#BE7685', '#9E253F', '#7D1C30', '#340C15'],
        ['#EBE8AF', '#D7D385', '#C4BC2F', '#8F8B20', '#413E0F'],
        ['#CEC0D8', '#A594B1', '#775C8B', '#4F3D5C', '#271E2E'],
    ],
    [
        ['#D4CAC5', '#A99E98', '#7F6E64', '#544943', '#2A2421'],
        ['#FFFFFF', '#A9A9A9', '#7F7F7F', '#545454', '#2A2A2A'],
        ['#CCCCCC', '#999999', '#666666', '#333333', '#000000'],
        ['#E2BEAE', '#C69C89', '#AA745B', '#8E3409', '#471A04'],
        ['#C7D5E1', '#A2B3C3', '#6D8BA5', '#485C6E', '#242E37'],
    ],
    [
        ['#FEFDFD', '#BFBFBF', '#999999', '#7F7F7F', '#2E2E2E'],
        ['#C5D8AE', '#9AB27D', '#678C39', '#445D26', '#222E13'],
        ['#F8DCC0', '#F1C8A0', '#EA9C4D', '#9C6833', '#4E3419'],
        ['#FCBEA8', '#F99E7C', '#F74100', '#A42B00', '#521500'],
        ['#E8BEC1', '#D2999D', '#BC565D', '#7D393E', '#3E1C1F'],
    ],
    [
        ['#D1D1D1', '#999999', '#6D6D6D', '#515151', '#1C1C1C'],
        ['#FFFFFF', '#C1E088', '#83B13A', '#577526', '#000000'],
        ['#CDEFFF', '#86B7CC', '#398EB5', '#265E76', '#132F3B'],
        ['#FFEDC6', '#ECD291', '#E3B333', '#977622', '#4B3B11'],
        ['#FFCFCF', '#DB9798', '#CA4C4D', '#863334', '#43191A'],
    ],
    [
        ['#AADBFF', '#7FC9FF', '#0094FF', '#0063A9', '#003154'],
        ['#FFFFFF', '#C8C5C5', '#666666', '#333333', '#000000'],
        ['#CEC1FB', '#B4A2F7', '#704CF3', '#4A32A2', '#251951'],
        ['#C2DEBE', '#9EBE9A', '#769E70', '#3F7D35', '#1F3E1A'],
        ['#F9D5AD', '#F4BF85', '#EF8614', '#9F590D', '#4F2C06'],
    ],
    [
        ['#FFFFFF', '#9E9E9E', '#6E6E6E', '#3E3E3E', '#0E0E0E'],
        ['#FDBED7', '#FB9EC3', '#F9408A', '#A62B5C', '#53152E'],
        ['#FFE9AA', '#FFDF7F', '#FFBF00', '#A97F00', '#543F00'],
        ['#CCCCCC', '#999999', '#666666', '#333333', '#000000'],
        ['#9CE8A6', '#6BD279', '#03BC1C', '#027D12', '#013E09'],
    ],
    [
        ['#FFFFFF', '#EFEFEF', '#ABABAB', '#3E3E3E', '#000000'],
        ['#FAAFD8', '#F687C4', '#F21890', '#A11060', '#500830'],
        ['#E8F9A7', '#DAF47B', '#B0E200', '#7D9F01', '#3E4F00'],
        ['#A8EAF4', '#7CDBEA', '#0DC4E0', '#088295', '#04414A'],
        ['#E09CE5', '#C56ACC', '#A707B2', '#6F0476', '#37023B'],
    ],
    [
        ['#FFFFFF', '#ECF0F1', '#BAC5CD', '#8892A1', '#484D56'],
        ['#D0E7F9', '#87B0D1', '#387FBB', '#285E88', '#0F3A69'],
        ['#A7BBDC', '#7790BB', '#2A5399', '#1C3766', '#0E1B33'],
        ['#FCCEC2', '#F9B6A3', '#F7714C', '#A44B33', '#522519'],
        ['#F3ACAC', '#E88282', '#DD1A1A', '#931111', '#490808'],
    ],
    [
        ['#BEE5E5', '#99E2E2', '#86CCCC', '#0FA8A1', '#0F5B5B'],
        ['#F9F1EA', '#E2CAB7', '#907057', '#90582D', '#6B3B17'],
        ['#FFFFFF', '#C1C0C0', '#7F7F7F', '#545454', '#2A2A2A'],
        ['#FBE4B7', '#F8D693', '#F4B22E', '#A3771F', '#513B0F'],
        ['#E8AECF', '#D28CB3', '#BC5E93', '#A5005D', '#52002E'],
    ],
    [
        ['#FFFFFF', '#999999', '#666666', '#333333', '#000000'],
        ['#FFFAAA', '#FFF87F', '#FFF200', '#A9A100', '#545000'],
        ['#ABE1E2', '#7EC4C6', '#2EA6AA', '#1E6F71', '#0F3738'],
        ['#AAFFEB', '#7FFFE1', '#00FFC3', '#00A982', '#005441'],
        ['#C1AED1', '#937FA4', '#644E77', '#321749', '#190B24'],
    ],
    [
        ['#FFF1DB', '#F9D195', '#FAC16A', '#FCB64C', '#C78725'],
        ['#FEFDFD', '#B49E91', '#8E6C57', '#8E6C57', '#62483D'],
        ['#BBE4CF', '#80C9A5', '#60977B', '#406452', '#203229'],
        ['#D3EEF7', '#AADEEF', '#7FA6B3', '#556F77', '#2A373B'],
        ['#F6CCD6', '#ED9CB0', '#B17584', '#764E58', '#3B272C'],
    ],
    [
        ['#FFFFFF', '#B4B4B4', '#8B8B8B', '#4F4F4F', '#000000'],
        ['#FAE3B2', '#F6D48C', '#F2B021', '#DDA31E', '#503A0B'],
        ['#F9B8AC', '#F39483', '#ED3312', '#9E220C', '#4F1106'],
        ['#D0AFC8', '#A17F99', '#734E6A', '#441839', '#220C1C'],
        ['#C1F4F5', '#A0EBEC', '#51E0E2', '#369597', '#1B4A4B'],
    ],
    [
        ['#FFFFFF', '#D9D9D9', '#929292', '#545454', '#000000'],
        ['#FFE1DE', '#FF8A7F', '#FF1500', '#A90E00', '#540700'],
        ['#FFAACE', '#FF7FB6', '#FF006E', '#A90049', '#540024'],
        ['#FFF0AA', '#FFE97F', '#FFD400', '#A98D00', '#544600'],
        ['#A7E4E8', '#7ACCD2', '#1EB2BC', '#14767D', '#0A3B3E'],
    ],
    [
        ['#FFFFFF', '#999999', '#666666', '#333333', '#000000'],
        ['#B3D7ED', '#8BBDDD', '#3592CC', '#236188', '#113044'],
        ['#FFEBAA', '#FFE17F', '#FFC300', '#A98200', '#544100'],
        ['#EDB3C7', '#DD8BA7', '#CC3569', '#882346', '#441123'],
        ['#F6F8AC', '#FFFC68', '#E7EA15', '#9A9C0E', '#4D4E07'],
    ],
    [
        ['#E0D4BE', '#DD974B', '#8F4120', '#600505', '#3A0404'],
        ['#F2EFE9', '#D1CCD0', '#959294', '#534F52', '#2B272A'],
        ['#CADBB1', '#A5B889', '#7D955C', '#4D721A', '#26390D'],
        ['#B9C0CF', '#89909F', '#585F6F', '#242D3F', '#12161F'],
        ['#D6B2B2', '#AD8888', '#845F5F', '#5B3535', '#330808'],
    ],
    [
        ['#FFFFFF', '#A69EA7', '#7A727B', '#4E464F', '#221B23'],
        ['#D5E4AC', '#B6CA7F', '#8DAF2D', '#5E751E', '#2F3A0F'],
        ['#FFECAA', '#FFE380', '#FFC802', '#A98501', '#544200'],
        ['#F4BEA9', '#EA9D7E', '#E04C11', '#95320B', '#4A1905'],
        ['#E5DDC8', '#CCBE99', '#998E72', '#665F4C', '#332F26'],
    ],
    [
        ['#000000', '#333333', '#666666', '#999999', '#FFFFFF'],
        ['#542A0F', '#A9541E', '#FF7E2D', '#FFBE96', '#FFD4B9'],
        ['#544D0C', '#A99B19', '#FFE926', '#FFF492', '#FFF7B6'],
        ['#4D112C', '#9A2258', '#E83384', '#EF92BC', '#F7B7D3'],
        ['#18414B', '#318397', '#4AC4E2', '#9DDCEC', '#BEEAF5'],
    ],
    [
        ['#000000', '#494440', '#8B8683', '#CFC7C2', '#FFFFFF'],
        ['#2D2525', '#5D4B2B', '#89744D', '#C1B297', '#EAE0CE'],
        ['#1C0417', '#472941', '#75546E', '#A3809C', '#D1ADC9'],
        ['#4B3F12', '#977E25', '#E2BE38', '#ECD993', '#F5E8B8'],
        ['#28330F', '#50661F', '#79992F', '#A7BB7A', '#CDDCAA'],
    ],
    [
        ['#FFFFFF', '#999999', '#666666', '#333333', '#000000'],
        ['#FFFBF1', '#D7D2C4', '#E4BF8F', '#C17F42', '#3E2816'],
        ['#B1D6E1', '#84B4C3', '#3B8CA5', '#275D6E', '#132E37'],
        ['#FFD5AA', '#FFC17F', '#FF8300', '#A95700', '#542B00'],
        ['#FFEBAA', '#FFE17F', '#FFC300', '#A98200', '#544100'],
    ],
];
