import React from 'react';

import { style, classes } from './highlight-layer.st.css';

const BACKGROUND_COLOR = 'rgba(65,151,236,0.20)';

export interface HighlightLayerProps {
    document?: Document;
    selector?: string;
    highlightStyle?: React.CSSProperties;
    className?: string;
}

export interface HighlightLayerState {
    selector: string;
}

interface Highlight {
    top: number;
    left: number;
    width: number;
    height: number;
}

export class HighlightLayer extends React.Component<HighlightLayerProps, HighlightLayerState> {
    public state: HighlightLayerState = { selector: '' };
    private highlights!: Highlight[];

    constructor(props: HighlightLayerProps) {
        super(props);
        this.setHighlights(this.props.selector);
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(_nextProps: HighlightLayerProps, { selector: nextSelector }: HighlightLayerState) {
        this.setHighlights(nextSelector);
    }

    public render() {
        const { highlightStyle: propHighlightStyle, className } = this.props;

        return (
            <div
                className={style(classes.root, className)}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    pointerEvents: 'none',
                    zIndex: 9999,
                }}
            >
                {this.highlights.map(({ top, left, width, height }, index) => {
                    const highlightStyle: React.CSSProperties = {
                        position: 'fixed',
                        boxSizing: 'border-box',
                        boxShadow: '0 0 0 0.05rem #3899ec',
                        backgroundColor: BACKGROUND_COLOR,
                        top,
                        left,
                        width,
                        height,
                        ...propHighlightStyle,
                    };

                    return <div className={classes.highlight} key={`highlight_${index}`} style={highlightStyle}></div>;
                })}
            </div>
        );
    }

    public update(selector: string) {
        this.setState({ selector });
    }

    private setHighlights(selector: string | undefined) {
        this.highlights = [];
        if (!selector) {
            return;
        }

        const queryDocument = this.props.document || document;
        const elements = queryDocument.querySelectorAll(selector);
        this.highlights = Array.from(elements).map((element: Element) => element.getBoundingClientRect());
    }
}
